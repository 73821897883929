import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { GroupedArticles } from "../../../../hooks/useArticles.js";
import { Topic, useStoreActions, useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { FulfillResponse } from "../../utils/types";
import { FilterParams } from "../areas/ThematicAreaView";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import ExploreIndicator from "./ExploreIndicator";
import ExploreTarget from "./ExploreTarget";
import GoalTable from "./GoalTable";

export interface InfographicModel {
  URL: string;
  title: string;
  key: string;
  Indicator: string;
  "Image name": string;
}
const GoalWrapper: React.FC<{
  area: Topic;
  filterParams: FilterParams;
  placeDcids?: string[];
  opened?: boolean;
  dataStories: GroupedArticles;
  infographics: InfographicModel[];
  store: object | undefined;
  goal?: string;
  target?: string;
  indicator?: string;
}> = ({ area, filterParams, placeDcids, opened, dataStories, infographics, store, goal, target, indicator }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [localIsFetchingFulfillment, setLocalIsFetchingFulfillment] = useState(false);
  const [localFulfillResponse, setLocalFulfillResponse] = useState<FulfillResponse>();
  const fetchTopicFulfillment = useStoreActions((a) => a.fetchTopicFulfillment);
  const fulfillmentsById = useStoreState((s) => s.fulfillments.byId);
  const [varToTopics, setVarToTopics] = useState(null);

  useEffect(() => {
    if (!area?.key || !filterParams?.location || !fulfillmentsById) {
      return;
    }
    setLoaded(false);
    (async () => {
      setLocalIsFetchingFulfillment(true);
      const topicDcids = [area.key];

      const fulfillment = await fetchTopicFulfillment({
        entityDcids: filterParams.location || placeDcids,
        variableDcids: topicDcids,
        fulfillmentsById,
      });
      console.log("Fulfillment response:", fulfillment);
      setLocalIsFetchingFulfillment(false);
      setLocalFulfillResponse(fulfillment);
      setLoaded(true);
    })();
  }, [filterParams?.location]);

  useEffect(() => {
    if (!localFulfillResponse?.relatedThings?.varToTopics) return;

    let t = Object.entries(localFulfillResponse?.relatedThings?.varToTopics).reduce((acc, [key, value]) => {
      const dcid = value?.[0]?.dcid;
      if (!acc[dcid]) {
        acc[dcid] = [];
      }
      // let tmpKey = acc[dcid].find(a=>(key.includes(a)||key==a))
      // if(!tmpKey){
      acc[dcid].push(key);
      // }
      return acc;
    }, {});
    setVarToTopics(t);
  }, [localFulfillResponse]);

  const columns: ColumnsType<DataType> = [
    {
      title: "label",
      dataIndex: "label",
      key: "key",
      render: (text, record) => {
        if (typeof record.key != "undefined") {
          return <Link to={`${routeDataCommonsConstants.GOAL}/${record.key}#${record.key}`}>{text}</Link>;
        }
        // else {
        //   const parent = findSubTheme(topics, record.key);
        //   return <Link to={`${routeDataCommonsConstants.GOAL}/${record.key}#${parent.key}+${record.key}`}>{text}</Link>;
        // }
      },
    },
  ];

  if (!loaded)
    return (
      <ContentCard>
        <Spinner />
      </ContentCard>
    );

  if (indicator && indicator !== "none" && goal && target && localFulfillResponse) {
    return (
      <ExploreIndicator
        goal={goal}
        target={target}
        indicator={indicator}
        fulfillResponse={localFulfillResponse}
        placeDcids={filterParams.location}
        // setCarouselProps={setCarouselProps}
        dataStories={dataStories?.[ROOT_TOPIC + "_" + indicator]}
        infographics={infographics}
      />
    );
  }
  if (target && target !== "none" && goal && localFulfillResponse)
    return (
      <ExploreTarget
        fulfillResponse={localFulfillResponse}
        placeDcids={filterParams.location}
        goal={goal}
        target={target}
        dataStories={dataStories}
        filterParams={filterParams}
        infographics={infographics}
      />
    );
  return (
    <GoalTable
      // setCarouselProps={onItemClick}
      placeDcids={[EARTH_PLACE_DCID]}
      localFulfillResponse={localFulfillResponse}
      columns={columns}
      filterParams={filterParams}
      // key={`${topicIndex}`}
      area={area}
      store={store}
      opened={opened}
      varToTopics={varToTopics}
      dataStories={dataStories}
      infographics={infographics}
    />
  );
};

export default GoalWrapper;
